<template>
  <div class="skipwrap">
    <div class="skip">
      <img src="@/assets/img/loading.gif" style="width: 400px" alt="" />
      <div :class="[lang]">{{ $t("Dataisloading") }}</div>
    </div>
  </div>
</template>
<script>
import { setAuthorization } from "@/utils/request";
import { mapMutations } from "vuex";
import { sendData } from "@/utils/burying-point";

export default {
  data() {
    return {
      userInfo: null,
    };
  },
  methods: {
    ...mapMutations("account", ["setUser", "setPermissions", "setRoles"]),
  },
  i18n: require("./i18n"),
  mounted() {
    console.log("==========", this.webAuth);
    // this.webAuth.parseHash(
    //   { hash: window.location.hash },
    //   (err, authResult) => {
    //     if (err) {
    //       return console.log(err);
    //     }
    //     if (!authResult) {
    //       return;
    //     }
    //     let that = this;
    //     this.webAuth.client.userInfo(
    //       authResult.accessToken,
    //       function (err, user) {
    //         console.log(err, user);
    //         that.userInfo = user;
    //         // Now you have the user's information
    //       }
    //     );
    //   }
    // );
    if (location.hash.indexOf("access_token") == -1) {
      // 授权失败
      sendData({
        event_name: 'loginskip_cancel'
      });
      this.$router.replace({ path: "/login" });
      return;
    }
    // 授权成功
    sendData({
      event_name: 'loginskip_accecpt'
    });
    this.$axios(
      "/user/login",
      {
        auth0_token: location.hash.split("=")[1].split("&")[0],
      },
      "post"
    ).then((res) => {
      if (res.code == "200") {
        localStorage.setItem("token", res.data.access_token);
        setAuthorization({
          token: res.data.access_token,
          expireAt: new Date("2099/12/30 23:59:59"),
        });
        this.$axios(
          "/user/info",
          {
            auth0_token: location.hash.split("=")[1].split("&")[0],
          },
          "post"
        ).then((res2) => {
          if (res2.code == 200) {
            this.setUser({
              ...res2.data,
              // hr_paid: 0,
              // sales_paid: 0,
              // free_info: {
              //   account: "",
              //   bind_type: "",
              //   start_date: "2024/10/31 23:59",
              //   end_date: "2024/11/21 23:59",
              // },
            });
            localStorage.setItem("showGuide", res.data.is_new ? 1 : 0);
            let { hrEndCapaignDate, saleEndCapaignDate } = res2.data;
            if (!hrEndCapaignDate && !saleEndCapaignDate) {
              this.$router.push("/sales/salesReps/campaign", {
                isNew: res.data.is_new == false ? 0 : 1,
              });
            } else if (saleEndCapaignDate && hrEndCapaignDate) {
              if (
                new Date(saleEndCapaignDate).getTime() >
                new Date(hrEndCapaignDate).getTime()
              ) {
                this.$router.push("/sales/salesReps/home");
              } else {
                this.$router.push("/sales/salesReps/home");
              }
            } else {
              this.$router.push("/sales/salesReps/home");
            }
          }
        });
      } else {
        sessionStorage.removeItem("token");
        this.$message.error(res.msg);
      }
    });
  },
};
</script>
<style lang="less" scoped>
.skipwrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  .skip {
    margin-top: -250px;
    width: 400px;
    height: 203px;

    font-size: 16px;
    text-align: center;
  }
}
</style>
