var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "skipwrap" }, [
    _c("div", { staticClass: "skip" }, [
      _c("img", {
        staticStyle: { width: "400px" },
        attrs: { src: require("@/assets/img/loading.gif"), alt: "" }
      }),
      _c("div", { class: [_vm.lang] }, [
        _vm._v(_vm._s(_vm.$t("Dataisloading")))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }